import React from 'react'
import FadeInSection from "../../effects/fadeinsection.jsx"
import Trenner from '../trenner/trenner.jsx'

const Castheadline = () => {
    return (
<div Name='Castheadline' className="test">
  <FadeInSection>
  <Trenner />
  <div className="relative">
    <div className="flex flex-row">
      <div className="md:basis-3/12">  
      </div>
      <div className="px-[0.5rem] md:px-[0.0rem] md:basis-6/12 mt-[100px] mb-[25px]"> 
      <h2 className="px-[0.5rem] md:px-[0.0rem] text-3xl md:text-5xl font-serif font-bold text-goldBright text-center">Gewinne Freunde, die du nie wolltest. Mache dir Feinde, die du deinem schlimmsten Feind nicht wünscht.</h2>
        
      </div>
    </div>
  </div>
  </FadeInSection>
</div>
    )
}

export default Castheadline;