import React from "react";
import Trennerklein from '../trenner/trennerklein.jsx';
import FadeInSection from "../../effects/fadeinsection.jsx"
import { ReactComponent as TonhalleIcon } from '../../assets/logos/tonhalleduesseldorf_logo.svg';
import { ReactComponent as JournalismuslabIcon } from '../../assets/logos/journalismuslab.svg';
import { ReactComponent as LandesmedienanstaltIcon } from '../../assets/logos/Landesmedienanstalt.svg';
import { ReactComponent as GrandQuestIcon } from '../../assets/logos/GrandQuest.svg';
import { ReactComponent as DuesseldorferSymphoniker } from '../../assets/logos/duesseldorfersymphoniker_logo.svg';

const Partner= () => {

    return (
      <>
      <FadeInSection>
      <div className="Partner text-goldBright text-center text-1xl mt-[80px] mb-[40px] font-sans tracking-wider">Produziert von 
        <div className="w-[8.0rem] fill-current mx-auto mt-[40px]"><TonhalleIcon/> 
        </div>
      </div>
      </FadeInSection>
      <FadeInSection>
      <div className="Produktion text-goldBright text-center text-1xl mt-[80px] mb-[40px] font-sans tracking-wider">und 
        <div className="w-[8.0rem] fill-current mx-auto mt-[40px]"><GrandQuestIcon/> 
        </div>
      </div>
      </FadeInSection>
      <div className="mb-[40px] mt-[100px]">
      <Trennerklein />
      </div>
      <FadeInSection>
      <div className="flex mx-auto px-[0.5rem] mt-[80px] justify-center">
        <div className="text-goldBright text-center text-1xl mb-[40px] font-sans tracking-wider">Unterstützt durch
        </div>
      </div>

      <div className="flex mx-auto px-[0.5rem] justify-center text-goldBright">
        <div className="w-[11.0rem] mx-10 fill-current"><JournalismuslabIcon/> </div>
        <div className="w-[8.0rem] mx-10 fill-current"><LandesmedienanstaltIcon/> </div>
      </div>
      </FadeInSection>
      <div className="mb-[40px] mt-[100px]">
      <Trennerklein />
      </div>

      <FadeInSection>
      <div className="Produktion text-goldBright text-center text-1xl mt-[80px] mb-[40px] font-sans tracking-wider">Musik von 
        <div className="w-[11.0rem] fill-current mx-auto mt-[40px]"><DuesseldorferSymphoniker/> 
        </div>
      </div>
      </FadeInSection>

     
      <div className="mb-[40px] mt-[60px]">
      <Trennerklein />
      </div>
      
    
      </>
        
     
    );
  }
  
export default Partner;
  