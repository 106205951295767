import React from "react";
import Navbar from './components/navbar/navbar.jsx';
import Teaser from './components/teaser/teaser.jsx';
import Story from './components/story/story.jsx';
import Cast from './components/castslider/cast.jsx';
import Locations from './components/Locations/Locations.jsx';
import Trailer from './components/trailer/trailer.jsx';
import Trenner from './components/trenner/trenner.jsx';
import Newsletter from './components/newsletter/newsletter.jsx';
import Partner from './components/partner/partner.jsx';
import Footer from './components/footer/footer.jsx';

import './App.css'
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./ui/slick-theme.css";



function App() {
 

  return ( <>
 
  <Navbar />
  <Teaser />
  <Trenner />
  <Story />
  <Cast/>
  <Trenner />
  <Locations />
  <Trenner />
  <Trailer />
  <Trenner />
  <Newsletter />
  <Trenner />
  <Partner />
  <Footer />

</>
)
}

export default App;
