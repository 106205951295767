// file HeartIcon.tsx
import React from "react";

function InstagramIcon(props: any) {
  return (
    <svg viewBox="0 0 500 500" fill="currentColor" {...props}>
    <path d="M250,0c-67.9,0-76.4,0.3-103.1,1.5c-26.6,1.2-44.8,5.4-60.7,11.6C69.8,19.5,55.9,28.1,42,42
		C28.1,55.9,19.5,69.8,13.1,86.2c-6.2,15.9-10.4,34.1-11.6,60.7C0.3,173.6,0,182.1,0,250c0,67.9,0.3,76.4,1.5,103.1
		c1.2,26.6,5.4,44.8,11.6,60.7c6.4,16.4,14.9,30.4,28.8,44.3c13.9,13.9,27.8,22.4,44.3,28.8c15.9,6.2,34.1,10.4,60.7,11.6
		c26.7,1.2,35.2,1.5,103.1,1.5c67.9,0,76.4-0.3,103.1-1.5c26.6-1.2,44.8-5.4,60.7-11.6c16.4-6.4,30.4-14.9,44.3-28.8
		c13.9-13.9,22.4-27.8,28.8-44.3c6.2-15.9,10.4-34.1,11.6-60.7c1.2-26.7,1.5-35.2,1.5-103.1c0-67.9-0.3-76.4-1.5-103.1
		c-1.2-26.6-5.4-44.8-11.6-60.7C480.5,69.8,471.9,55.9,458,42c-13.9-13.9-27.8-22.4-44.3-28.8c-15.9-6.2-34.1-10.4-60.7-11.6
		C326.4,0.3,317.9,0,250,0z M250,45c66.8,0,74.7,0.3,101,1.5c24.4,1.1,37.6,5.2,46.4,8.6c11.7,4.5,20,10,28.7,18.7
		c8.7,8.7,14.2,17.1,18.7,28.7c3.4,8.8,7.5,22,8.6,46.4c1.2,26.4,1.5,34.3,1.5,101s-0.3,74.7-1.5,101c-1.1,24.4-5.2,37.6-8.6,46.4
		c-4.5,11.7-10,20-18.7,28.7c-8.7,8.7-17.1,14.2-28.7,18.7c-8.8,3.4-22,7.5-46.4,8.6c-26.4,1.2-34.3,1.5-101,1.5s-74.7-0.3-101-1.5
		c-24.4-1.1-37.6-5.2-46.4-8.6c-11.7-4.5-20-10-28.7-18.7c-8.7-8.7-14.2-17.1-18.7-28.7c-3.4-8.8-7.5-22-8.6-46.4
		c-1.2-26.4-1.5-34.3-1.5-101s0.3-74.7,1.5-101c1.1-24.4,5.2-37.6,8.6-46.4c4.5-11.7,10-20,18.7-28.7c8.7-8.7,17.1-14.2,28.7-18.7
		c8.8-3.4,22-7.5,46.4-8.6C175.3,45.3,183.2,45,250,45"/>
	<path d="M250,333.3c-46,0-83.3-37.3-83.3-83.3c0-46,37.3-83.3,83.3-83.3c46,0,83.3,37.3,83.3,83.3
		C333.3,296,296,333.3,250,333.3z M250,121.6c-70.9,0-128.4,57.5-128.4,128.4c0,70.9,57.5,128.4,128.4,128.4
		c70.9,0,128.4-57.5,128.4-128.4C378.4,179.1,320.9,121.6,250,121.6 M413.5,116.5c0,16.6-13.4,30-30,30c-16.6,0-30-13.4-30-30
		c0-16.6,13.4-30,30-30C400,86.5,413.5,100,413.5,116.5"/></svg>
  );
}
export default InstagramIcon;