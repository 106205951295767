import React from 'react'
import FadeInSection from "../../effects/fadeinsection.jsx"
import hoerprobeCoverAni from '../../assets/OM_HOERPROBE_low.mp4'
import platzhalter from '../../assets/loading_animation_ready_klein.gif'
import AudioPlayer from 'react-h5-audio-player';
import 'react-h5-audio-player/lib/styles.css';
import demoAudio from '../../audio/Octavia_Trailer_Test-Open-Medium.mp3'
import MyModalFeedback from '../../components/modals/modalfeedback.jsx';

const Trailer = () => {
    return (
<div Name='Trailer' className="Trailer">
  <FadeInSection>
  <div className="relative">
    <div className="flex flex-row">
      <div className="md:basis-3/12">  
      </div>
      <div className="px-[0.5rem] md:px-[0.0rem] md:basis-6/12 mt-[100px]"> 
      <h2 className="px-[0.5rem] md:px-[0.0rem] text-3xl md:text-5xl font-serif font-bold text-goldBright text-center">Begib dich auf eine wahnwitzige Zeitreise. Mit Octavia. Und unserem Trailer.</h2>
        <div className="mt-[0.7rem] text-2xl font-sans tracking-wider font-normal text-goldBright text-center mb-6">

        </div>
          <div className="md:px-[2.0rem] lg:px-[8.0rem] mb-[2.7rem]">
            <div className="hoerprobeCover"
            dangerouslySetInnerHTML={{
      __html: `<video autoplay loop muted playsinline poster=${platzhalter} class='rounded-t-lg'>
<source src=${hoerprobeCoverAni} type="video/mp4" />
Your browser does not support the video tag.
</video>`,
    }} />

        
            <AudioPlayer className="rhap_container rounded-b-lg"
            showJumpControls={false}
            customVolumeControls={[]}
            customAdditionalControls={[]}
            src={demoAudio}
            onPlay={e => console.log("onPlay")}
            // other props here
            />
            {/* <div className="modal mb-[100px]"><MyModalFeedback /></div> */} 
            </div>
            
      </div>
      <div className="md:basis-3/12">  
      </div>
    </div>
  </div>
  </FadeInSection>
</div>
    )
}

export default Trailer;