import React from 'react'
import Slider from "react-slick";
import UrUhr from '../../assets/clock_cave.jpg'
import Bibliothek from '../../assets/bibliothek.jpg'
import Weltausstellung from '../../assets/worldexhibition.jpg'
import Locationsheadline from './Locationsheadline.jsx'
import FadeInSection from "../../effects/fadeinsection.jsx"
import Arrowleft from '../../images/arrowleft.png'
import Arrowright from '../../images/arrowright.png'


export default function Welt() {
  var settings = {
  
   
    infinite: true,
    autoplay: true,
    mobileFirst: true, 
    adaptiveHeight: false,
    
    prevArrow: <img className="test" src={Arrowleft} alt='Pfeil nach links'/>,
    nextArrow: <img className="test" src={Arrowright} alt='Pfeil nach rechts'/>,

    autoplaySpeed: 2000,
    speed: 1000,
    pauseOnHover: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          
      
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
          
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
      // You can unslick at a given breakpoint now by adding:
      // settings: "unslick"
      // instead of a settings object
    ]
    
  };
  return (
    
  
      


    <div className="Welt">
      <Locationsheadline />
    
      <FadeInSection>
      <div className="px-[5.0rem] md:px-[11rem] mb-[3rem]">
    <Slider {...settings}>
      
      <div>
        <img className="px-0" src={UrUhr} alt='Ur-Uhr' /> 
        
      </div>
      <div>
        <img className="px-0" src={Weltausstellung} alt='Die phantastische Weltausstellung' /> 
        
      </div>
      <div>
        <img className="px-0" src={Bibliothek} alt='Die Bibliothek der ungeschriebenen Werke' /> 
        
      </div>
      
      
    </Slider>
    </div>
    </FadeInSection>
    
    </div>
  );
}
