import React from 'react'
import platzhalter from '../../assets/loading_animation_ready.gif'
import teasertrailer from '../../assets/OM_OHTOPIA_3198x2262.mp4'
import teasertrailermobile from '../../assets/OM_OHTOPIA_1600x1132.mp4'
import FadeInSection from "../../effects/fadeinsection.jsx"

const Teaser = () => {
    return (
          
      <div className="container teaser h-screen mb-[130px] text-black">
        <div className="Stoerer"><button className="trailerStoerer font-sans font-normal cursor-default md:text-2xl text-1xl rounded-full z-10 transform_50 w-[7.0rem] h-[7.0rem] md:w-[10.0rem] md:h-[10.0rem] bg-goldBright">Die <p>Fantasy</p><p>Hörspiel</p><p>Serie</p></button></div>
        <div className='lg:hidden'> 
        <div className='teaser flex flex-col h-screen'
          
            dangerouslySetInnerHTML={{
      __html: `<video autoplay loop muted playsinline poster=${platzhalter} class='object-cover h-full w-full bg_vid absolute'>
<source src=${teasertrailermobile} type="video/mp4" />
Your browser does not support the video tag.
</video>`,
    }} />

</div>

<div className='hidden lg:block'>
  
<div className='teaser flex flex-col h-screen'
          
          dangerouslySetInnerHTML={{
    __html: `<video autoplay loop muted playsinline poster=${platzhalter} class='object-cover h-full w-full bg_vid absolute'>
<source src=${teasertrailer} type="video/mp4" />
Your browser does not support the video tag.
</video>`,
  }} />
      </div>

      
      </div>

      

      

      
    
    )
}

export default Teaser;

