import React from 'react'
import FadeInSection from "../../effects/fadeinsection.jsx"
import MailchimpSubscribe from 'react-mailchimp-subscribe';
import NewsletterForm from './NewsletterForm.jsx';


const Newsletter = () => {
  
  const MAILCHIMP_URL = "https://xyz.us21.list-manage.com/subscribe/post?u=ae324aea8493687bfb5746ce2&amp;id=eb0ad6805e";

    return (

    <div Name='newsletter' className="newsletter mt-[100px] mb-[130px]">
      <FadeInSection>
      <div className="relative">
        <div className="flex flex-row">
            <div className="md:basis-3/12">  
            </div>
            <div className="px-[0.5rem] md:px-[0.0rem] md:basis-6/12"> 
              <h2 className="px-[0.5rem] md:px-[0.0rem] text-3xl md:text-5xl font-serif font-bold text-goldBright text-center">Wie geht es weiter? Das erfährst du in unserem Newsletter
              </h2> 
              <div className="mt-[0.7rem] text-1xl md:text-2xl font-sans tracking-wider font-normal text-goldBright text-center mb-6">Du möchtest wissen, wie, wenn und wann es mit Octavia Mars weitergeht? Dann abonniere unseren Newsletter.
              </div>
              <MailchimpSubscribe
      url={ MAILCHIMP_URL }
      render={ ( props ) => {
        const { subscribe, status, message } = props || {};
        return (
          <NewsletterForm
            status={ status }
            message={ message }
            onValidated={ formData => subscribe( formData ) }
          />
        );
      } }
    />
          
            </div>
            <div className="md:basis-3/12">              
            </div>
          </div>
      </div>
      </FadeInSection>
    </div>

    )
}

export default Newsletter;
