import React from 'react'
import Slider from "react-slick";
import FadeInSection from "../../effects/fadeinsection.jsx"
import Octavia from '../../assets/octavia_card.png'
import Rabbit from '../../assets/rabbit_card.png'
import DergrosseAndere from '../../assets/dergrosseandere_card.png'
import Beethoven from '../../assets/beethoven_card.png'
import ClaraSchumann from '../../assets/schumann_card.png'
import Will from '../../assets/will_card.png'
import Debussy from '../../assets/debussy_card.png'
import Zwifler from '../../assets/zwifler_card.png'
import MaleGaze from '../../assets/malegaze_card.png'
import Arrowleft from '../../images/arrowleft.png'
import Arrowright from '../../images/arrowright.png'
import Castheadline from './castheadline.jsx'

export default function Cast() {
  var settings = {
   
    infinite: true,
    autoplay: true,
    mobileFirst: true, 
    adaptiveHeight: false,
   
    
    prevArrow: <img className="test" src={Arrowleft} />,
    nextArrow: <img className="test" src={Arrowright} />,
    autoplaySpeed: 3000,
    speed: 1000,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          
      
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
          
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
      // You can unslick at a given breakpoint now by adding:
      // settings: "unslick"
      // instead of a settings object
    ]
    
  };
  return (
    
  
      


    <div className="Cast">
      <Castheadline />
    
      <FadeInSection>
      <div className="px-[5.0rem] md:px-[11rem] mb-[3rem]">
    <Slider {...settings}>
      
      <div>
        <img className="px-0 mr-2 md:px-1 md:mr-4" src={Octavia} alt='Octavia' /> 
        <h2 className="my-[1.5rem] px-[1.5rem] text-3xl md:text-5xl font-serif font-bold text-goldBright text-center"><span>Octavia</span><br/><span>Mars</span></h2> 
      </div>
      <div>
        <img className="px-1 ml-1 mr-1 md:px-4 md:ml-2 md:mr-3" src={Beethoven} alt='Beethoven' /> 
        <h2 className="mx-auto my-[1.5rem] px-[1.5rem] text-3xl md:text-5xl font-serif font-bold text-goldBright text-center">Beethoven</h2>
      </div>
      <div>
        <img className="px-0 ml-1 mr-1 md:px-0 md:ml-1" src={DergrosseAndere} alt='Der große Andere' /> 
        <h2 className="mx-auto my-[1.5rem] px-[1.5rem] text-3xl md:text-5xl font-serif font-bold text-goldBright text-center">Der große Andere</h2> 
      </div>
      <div>
        <img className="px-0 ml-0 mr-0 md:px-1 md:mr-4" src={Rabbit} alt='Rabbit' /> 
        <h2 className="mx-auto my-[1.5rem] px-[1.5rem] text-3xl md:text-5xl font-serif font-bold text-goldBright text-center">Rabbit</h2> 
      </div>
      <div>
        <img className="px-1 ml-1 mr-1 md:px-4 md:ml-2 md:mr-3" src={Debussy} alt='Debussy' /> 
        <h2 className="mx-auto my-[1.5rem] px-[1.5rem] text-3xl md:text-5xl font-serif font-bold text-goldBright text-center">Debussy</h2>
      </div>
      <div>
        <img className="px-1 ml-1 mr-0 md:px-4 md:ml-1" src={MaleGaze} alt='Gale Maze' /> 
        <h2 className="my-[1.5rem] px-[1.5rem] text-3xl md:text-5xl font-serif font-bold text-goldBright text-center"><span>Gale</span><br/><span>Maze</span></h2>
      </div>
      <div>
        <img className="px-0 ml-0 mr-0 md:px-1 md:mr-4" src={Will} alt='Will' /> 
        <h2 className="mx-auto my-[1.5rem] px-[1.5rem] text-3xl md:text-5xl font-serif font-bold text-goldBright text-center">Will</h2> 
      </div>
      <div>
        <img className="px-1 ml-1 mr-0 md:px-4 md:ml-2 md:mr-3" src={ClaraSchumann} alt='Clara Schumann' /> 
        <h2 className="my-[1.5rem] px-[1.5rem] text-3xl md:text-5xl font-serif font-bold text-goldBright text-center"><span>Clara</span><br/><span>Schumann</span></h2> 
      </div>
      <div>
        <img className="px-1 ml-0 mr-0 md:px-4 md:ml-1" src={Zwifler} alt='Zwifler' /> 
        <h2 className="mx-auto my-[1.5rem] px-[1.5rem] text-3xl md:text-5xl font-serif font-bold text-goldBright text-center">Zwifler</h2> 
      </div>
      
    </Slider>
    </div>
    </FadeInSection>
    
    </div>
  );
}

