
import React from 'react'
import { Fragment, useState } from 'react'
import MyModalDatenschutz from '../../components/modals/modaldatenschutz.jsx';
import MyModalUeberuns from '../../components/modals/modalueberuns.jsx';
import MyModalKontakt from '../../components/modals/modalkontakt.jsx';
import MyModalImpressum from '../../components/modals/modalimpressum.jsx';


const Footer = () => {

  
    
    return (

<footer>

<div className="flex mx-auto px-[0.5rem] mt-[100px] justify-center">
<span className="mx-3 p-0"><MyModalUeberuns/></span>
<span className="mx-3 p-0"><MyModalKontakt/></span>
<span className="mx-3 p-0"><MyModalImpressum/></span>
</div>
<div className="flex mx-auto px-[0.5rem] justify-center mb-[0.5rem]">
<div className="text-1xl font-sans font-normal tracking-wider text-goldBright">Built with 🔥 in Oh!topia</div>
</div>
</footer> 

    )
}

export default Footer;