import React from 'react'
import { useState } from 'react';
import { decode } from 'html-entities';

const NewsletterForm = ( { status, message, onValidated }) => {

  const [ error, setError ] = useState(null);
  const [ email, setEmail ] = useState(null);

  /**
   * Handle form submit.
   *
   * @return {{value}|*|boolean|null}
   */
  const handleFormSubmit = () => {

    setError(null);

    if ( ! email ) {
      setError( 'Please enter a valid email address' );
      return null;
    }

    const isFormValidated = onValidated({ EMAIL: email });

    // On success return true
    return email && email.indexOf("@") > -1 && isFormValidated;
  }

  /**
   * Handle Input Key Event.
   *
   * @param event
   */
  const handleInputKeyEvent = ( event ) => {
    setError(null);
    // Number 13 is the "Enter" key on the keyboard
    if (event.keyCode === 13) {
      // Cancel the default action, if needed
      event.preventDefault();
      // Trigger the button element with a click
      handleFormSubmit();
    }
  }

  /**
   * Extract message from string.
   *
   * @param {String} message
   * @return {null|*}
   */


  return (
    <>
      <div className="flex flex-col md:flex-row justify-center mb-6 mx-0">
     
        <div className="mc-field-group flex flex-col mx-0 justify-center">
          <input
            onChange={(event) => setEmail(event?.target?.value ?? '')}
            type="email"
            placeholder="rabbit@octaviamars.xyz"
            className="font-sans tracking-wider font-normal text-1xl md:text-2xl text-goldBright placeholder:text-goldDark pt-3 pb-4 px-6 md:px-10 lg:pt-3 lg:pb-4 lg:px-12 bg-undergroundDark focus:bg-undergroundBright hover:ring-goldDark hover:ring-2 hover:ring-offset-0 focus:ring-goldBright focus:ring-2 focus:ring-offset-0 focus:bg-opacity-70 duration-1000 rounded-lg md:mr-2 mb-2 md:mb-0 outline-none"
            onKeyUp={(event) => handleInputKeyEvent(event)}
          />
        </div>
        <div className="button flex flex-col mx-0 justify-center">
          <button className="bg-primary text-1xl md:text-2xl font-sans tracking-wider font-bold bg-goldDark rounded-lg pt-3 pb-4 px-6 md:px-10 lg:pt-3 lg:pb-4 lg:px-12 cursor-pointer hover:bg-goldBright duration-1000" onClick={handleFormSubmit}>
            Abonnieren
          </button>
          </div>
     
      </div>
      <div className="newsletter-form-info text-1xl text-goldDark text-center">
        {status === "sending" && <div>Sende …</div>}
        {status === "error" || error ? (
          <div
            className="newsletter-form-error">
            Hoppla! Dir ist ein Fehler unterlaufen. Probiere es noch mal, aber mit Konzentration.
          </div>
        ) : null }
        {status === "success" && status !== "error" && !error && (
          <div dangerouslySetInnerHTML={{ __html: decode(message) }} />
        )}
      </div>
    </>
  );
}

export default NewsletterForm