import React from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { Fragment, useState } from 'react'

export default function MyModalImpressum() {
  let [isOpen, setIsOpen] = useState(false)

  function closeModal() {
    setIsOpen(false)
  }

  function openModal() {
    setIsOpen(true)
  }


  return (
    <>
      <div className="Impressum p-0 mx-0">
        <button
          type="button"
          onClick={openModal}
          className="p-0 mx-0 text-goldDark hover:text-goldBright font-sans font-normal tracking-wider duration-1000">Impressum
        </button>
      </div>
     

      <Transition appear show={isOpen} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={closeModal}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-1000"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-1000"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-40" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto m-[0.0rem]">
            <div className="flex items-center justify-center min-h-full p-4 text-center -m-[0.5rem]">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-1000"
                enterFrom="opacity-0 scale-90"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-500"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-90"
              >
                <Dialog.Panel className="w-full max-w-6xl p-6 overflow-hidden align-middle transition-all transform bg-undergroundDark shadow-xl rounded-lg m-[0.0rem]">
                  <Dialog.Title
                    as="h3"
                    className="text-4xl md:text-5xl font-serif font-bold text-goldBright leading-6 text-center mb-[2.2rem]"
                  >Impressum</Dialog.Title>

                  <button type="button" className="absolute top-3 right-2.5 text-goldBright bg-transparent hover:text-goldBright rounded-full border-2 border-goldDark hover:border-goldBright p-1.5 ml-auto inline-flex items-center transition-all duration-1000" onClick={closeModal}>
                <svg aria-hidden="true" className="w-6 h-6" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clip-rule="evenodd"></path></svg>
                
            </button>

                  <div className="fliestext">
                    <div className="mt-[0.7rem] text-2xl md:text-2xl font-sans font-normal tracking-wider text-goldBright text-left">
                    <div className="Absatz mb-[1.4rem]">
                    <p>Angaben gemäß § 5 TMG:</p>
                    <p>Grand Quest Production –</p>
                    <p>Brandt Paukstat Turiak GbR</p>
                    <p>Lorettostraße 33</p>
                    <p>40219 Düsseldorf</p>
                    </div>
                    <div className="Absatz mb-[1.4rem]">
                    <p>Kontakt:</p>
                    <p>production@grandquest.de</p>
                    <p>+49(0)211.13721660</p>
                    </div>

                    <div className="Absatz mb-[1.4rem]">
                    <p>Grand Quest Production –</p>
                    <p>Verantwortlich für den Inhalt nach § 55 Abs. 2 RStV:</p>
                    <p>Brandt Paukstat Turiak GbR</p>
                    <p>Lorettostraße 33</p>
                    <p>40219 Düsseldorf</p>
                    </div>
                    
                    <div className="Absatz mb-[1.4rem]">
                    <p>Streitschlichtung</p>
                    <p>Die Europäische Kommission stellt eine Plattform zur Online-Streitbeilegung (OS) bereit: ec.europa.eu. Unsere E-Mail-Adresse finden Sie oben im Impressum. Wir sind nicht bereit oder verpflichtet, an Streitbeilegungsverfahren vor einer Verbraucherschlichtungsstelle teilzunehmen.</p>
                    </div>

                    <div className="Absatz mb-[1.4rem]">
                    <p>Haftung für Inhalte</p>
                    <p>Als Diensteanbieter sind wir gemäß § 7 Abs.1 TMG für eigene Inhalte auf diesen Seiten nach den allgemeinen Gesetzen verantwortlich. Nach §§ 8 bis 10 TMG sind wir als Diensteanbieter jedoch nicht verpflichtet, übermittelte oder gespeicherte fremde Informationen zu überwachen oder nach Umständen zu forschen, die auf eine rechtswidrige Tätigkeit hinweisen.</p>
                    </div>

                    <div className="Absatz mb-[1.4rem]">
                    <p>Verpflichtungen zur Entfernung oder Sperrung der Nutzung von Informationen nach den allgemeinen Gesetzen bleiben hiervon unberührt. Eine diesbezügliche Haftung ist jedoch erst ab dem Zeitpunkt der Kenntnis einer konkreten Rechtsverletzung möglich. Bei Bekanntwerden von entsprechenden Rechtsverletzungen werden wir diese Inhalte umgehend entfernen.</p>
                    </div>

                    <div className="Absatz mb-[1.4rem]">
                    <p>Haftung für Links</p>
                    <p>Unser Angebot enthält Links zu externen Websites Dritter, auf deren Inhalte wir keinen Einfluss haben. Deshalb können wir für diese fremden Inhalte auch keine Gewähr übernehmen. Für die Inhalte der verlinkten Seiten ist stets der jeweilige Anbieter oder Betreiber der Seiten verantwortlich. Die verlinkten Seiten wurden zum Zeitpunkt der Verlinkung auf mögliche Rechtsverstöße überprüft. Rechtswidrige Inhalte waren zum Zeitpunkt der Verlinkung nicht erkennbar.</p>

                    <p>Eine permanente inhaltliche Kontrolle der verlinkten Seiten ist jedoch ohne konkrete Anhaltspunkte einer Rechtsverletzung nicht zumutbar. Bei Bekanntwerden von Rechtsverletzungen werden wir derartige Links umgehend entfernen.</p>
                    </div>

                    <div className="Absatz mb-[1.4rem]">
                    <p>Urheberrecht</p>
                    <p>Die durch die Seitenbetreiber erstellten Inhalte und Werke auf diesen Seiten unterliegen dem deutschen Urheberrecht. Die Vervielfältigung, Bearbeitung, Verbreitung und jede Art der Verwertung außerhalb der Grenzen des Urheberrechtes bedürfen der schriftlichen Zustimmung des jeweiligen Autors bzw. Erstellers. Downloads und Kopien dieser Seite sind nur für den privaten, nicht kommerziellen Gebrauch gestattet.</p>

                    <p>Soweit die Inhalte auf dieser Seite nicht vom Betreiber erstellt wurden, werden die Urheberrechte Dritter beachtet. Insbesondere werden Inhalte Dritter als solche gekennzeichnet. Sollten Sie trotzdem auf eine Urheberrechtsverletzung aufmerksam werden, bitten wir um einen entsprechenden Hinweis. Bei Bekanntwerden von Rechtsverletzungen werden wir derartige Inhalte umgehend entfernen.</p></div>
                    </div>

                  </div>

                  <div className="mt-4 mx-auto">
                    <button
                      type="button"
                      className="inline-flex justify-center bg-primary text-2xl md:text-2xl font-sans font-bold bg-goldDark rounded-lg pt-3 pb-4 px-6 md:px-10 lg:pt-3 lg:pb-4 lg:px-12 cursor-pointer hover:bg-goldBright duration-1000"
                      onClick={closeModal}
                    >
                      Klar, Danke!
                    </button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  )
}