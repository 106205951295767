import React from 'react'
import TrennElement from '../../images/trenner.png'

const TrennerKlein= () => {

    
    return (
      <div className="TrennElementKlein">
        
        <div className="flex flex-row">
        <div className="sm:basis-3/12 md:basis-4/12 lg:basis-4/12"> 
        </div>
        <div className="px-[0.5rem] md:px-[0.0rem] sm:basis-6/12 md:basis-8/12 lg:basis-4/12 mx-auto"> 
        <img className="w-[200px] mx-auto" src={TrennElement} alt='Trenner' />
        </div>
        <div className="sm:basis-3/12 md:basis-4/12 lg:basis-4/12"> 
        </div>
        </div>
        </div>
     
    );
  }
  
export default TrennerKlein;
  
