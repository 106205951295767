import React from 'react'
import {FaBars} from 'react-icons/fa'
import { Link, Button, Element, Events, animateScroll as scroll, scrollSpy, scroller } from 'react-scroll'
import octavialogo from '../../assets/octavialogo.png'
import InstagramIcon from '../../HeartIcon.tsx'
import './navbar.css'


const Navbar = () => {
    return (
        <div className='flex flex-col bg-black'>
        <div className='navbar w-full fixed top-0 z-10 h-[8.5rem] p-[0.5rem] bg-gradient-to-b from-black'>
            <div className='flex items-start justify-between'>
            
                <div className='logo w-[18rem]'><Link activeClass="active" to="teaser" spy={true} smooth={true} offset={0} duration={1000}><img className="cursor-pointer" src={octavialogo} alt='Logo' /></Link>
                </div>

                <div className='menuitems1 hidden md:block'>
                <ul className='nav-menu flex items-start justify-between pt-[0.5rem]'>
                    <li className='nav-item font-sans font-normal tracking-wider cursor-pointer hover:text-goldBright text-2xl text-goldDark transition-all duration-1000'><Link activeClass="active" to="story" spy={true} smooth={true} offset={-100} duration={1000}>Story</Link></li>
                    <li className='nav-item font-sans font-normal tracking-wider cursor-pointer hover:text-goldBright ml-[0.8rem] mr-[0.4rem] text-2xl text-goldDark transition-all duration-1000'><Link activeClass="active" to="Cast" spy={true} smooth={true} offset={-100} duration={1000}>Figuren</Link></li>
                    <li className='nav-item font-sans font-normal tracking-wider cursor-pointer hover:text-goldBright ml-[0.4rem] mr-[0.4rem] text-2xl text-goldDark transition-all duration-1000'><Link activeClass="active" to="Welt" spy={true} smooth={true} offset={-100} duration={1000}>Welt</Link></li>
                    <li className='nav-item font-sans font-normal tracking-wider cursor-pointer hover:text-goldBright mr-[0.8rem] ml-[0.4rem] text-2xl text-goldDark transition-all duration-1000'><Link activeClass="active" to="Trailer" spy={true} smooth={true} offset={-80} duration={1000}>Trailer</Link></li>
                    <li className='nav-item font-sans font-normal tracking-wider cursor-pointer hover:text-goldBright text-2xl text-goldDark transition-all duration-1000'><Link activeClass="active" to="newsletter" spy={true} smooth={true} offset={-80} duration={1000}>Newsletter</Link></li>
                </ul>
                </div>

                <div className='menuitems2 w-60 flex justify-end'>
                <ul className='nav-menu pt-[0.5rem]'>
                    <li><a href="https://www.instagram.com/iamoctaviamars/" target="_blank"><button className="inline-flex items-center justify-center pt-2 text-goldDark transition-colors duration-1000 hover:text-goldBright">
                    <InstagramIcon className="w-5 h-5 fill-current" />
                    </button></a></li>
                </ul>
                </div>

            </div>
        </div>
        </div>
    )
}

export default Navbar;