import React from 'react'
import FadeInSection from "../../effects/fadeinsection.jsx"

const Story = () => {
    return (
<div Name='story' className="story">
  <FadeInSection>
  <div className="relative">
    <div className="flex flex-row">
      <div className="md:basis-3/12">  
      </div>
      <div className="px-[0.5rem] md:px-[0.0rem] md:basis-6/12"> 
        <h2 className="mt-[110px] px-[0.5rem] md:px-[0.0rem] text-3xl md:text-5xl font-serif font-bold text-goldBright text-center">Musik ist die einzige real-existierende Magie auf der Welt. Was ist, wenn sie erlischt?
        </h2>
        <div className="mt-[0.7rem] mb-[110px] text-1xl md:text-2xl font-sans tracking-wider font-normal text-goldBright text-center">Sie ist gewitzt. Kühn. Und hoch unmusikalisch. Ausgerechnet die 11jährige Octavia stolpert in eine gigantische Verschwörung, die zum Ziel hat, jedwede Musik auszulöschen. Damit die Welt nicht in Stille versinkt und die Menschen bloß noch ihre Ängste hören, muss sie durch die Zeit reisen, den Kontinent Oh!topia retten und einen wahnwitzig tief verborgenen Schatz finden: ihre eigene Melodie.

 
        </div>      
      </div>
      <div className="md:basis-3/12">  
      </div>
    </div>
  </div>
  </FadeInSection>
</div>
    )
}

export default Story;